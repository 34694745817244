import React from "react"
import Slider from "react-slick";
import _ from 'lodash'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SliderArrow from './sliderArrow'
import 'bootstrap/dist/css/bootstrap.css'
import BootstrapCarousel from 'react-bootstrap/Carousel'

class Carousel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            photos: []
        }
    }

    componentDidMount() {
        this.setState({
            photos: _.sampleSize(this.props.images, this.props.images.length)
        });
    }

    render() {

        return <BootstrapCarousel indicators={false}>
            {this.state.photos.length > 0 ? this.state.photos.map((photo, i) => {
                return <BootstrapCarousel.Item key={i}>
                    <img
                        className="d-block w-100"
                        src={photo.node.childImageSharp.fluid.src}
                    />
                </BootstrapCarousel.Item>
            }) :
                <div>
                    <img src={this.props.placeholder} style={{ width: "100%", objectFit: "cover" }} />
                </div>
            }
        </BootstrapCarousel>
    }
}

export default Carousel
