import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'
import Carousel from '../components/carousel'

class PhotosPage extends React.Component {
  render() {
    return <StaticQuery
      query={graphql`
    query {
        image1: file(relativePath: {eq: "transparent.png"}) {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 900, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allFile(filter: {
          extension: {regex: "/(jpg)/"}
          relativeDirectory: {
            eq: "photos"
          }
        }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 900, quality: 95) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
  `}
      render={function (data) {
        return (<Layout>
          <SEO title="Photos" />
          <div className="Site-inner" data-controller="HeaderOverlay" id="yui_3_17_2_1_1538632099177_588">
            <header className="Header Header--bottom Header--overlay">
              <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
                <div data-nc-container="bottom-left">
                </div>
                <div data-nc-container="bottom-center">
                </div>
                <div data-nc-container="bottom-right">
                  <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                    data-content-field="navigation">
                  </nav>
                </div>
              </div>
            </header>
            <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
              <div className="Loader"></div>
              <main className="Main Main--gallery-list" id="yui_3_17_2_1_1538632099177_587">
                <section className="Main-content" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                  <div className="container">
                    <Carousel placeholder={data.image1.childImageSharp.fluid.src} images={data.allFile.edges} />
                  </div>
                </section>
              </main>
            </div>
          </div>
        </Layout>
        )
      }
      }
    />
  }
}

export default PhotosPage
